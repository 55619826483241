<template>
  <transition name="modal-window" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @after-leave="afterLeave">
    <div class="modal-window-wrapper">
      <article v-if="showModal" :class="{'modal-window':true, 'scrollable': scrollable, 'has-controls':controlButtons, foreground: foreground}">
        <div class="modal-content">
          <slot />
        </div>
      
        <div v-if="loading" class="modal-progress">
          <spinner />
        </div>
      
        <div v-if="controlButtons && controlButtons.length > 0 && showControlButtons" class="modal-control">
          <i class="flexible-space" />
          <button v-for="(def, index) in controlButtons" :key="index" :class="def['class']" @click="controlButtonClick(def)">
            {{ def['label'] }}
          </button>
        </div>
      </article>
    </div>
  </transition>
</template>

<style lang="scss">
  .modal-cover                { position: fixed; z-index: 21; top: 0; right: 0px; left: 0px; bottom: 0; background: rgba(0,0,0,0.14); opacity: 1;  }
  .modal-cover                { transition: all 0.4s cubic-bezier(.21,.18,0,1); opacity: 1;}
  .modal-cover.hidden         { transition: all 0.25s cubic-bezier(.21,.18,0,1); opacity: 0;}

  
  
  .modal-window               { position: fixed; z-index: 200; left: 50%; transform: translate(-50%, 0px); top: 10%;
                                background: white; border-radius: var(--br); box-shadow: 0 0 24px rgba(0, 0, 0, 0.8);
                                padding: 16px; overflow: hidden;
                                width: max-content; min-width: 400px; max-width: 80%;  
                                
                                transition: all 0.4s ease;

                                // Establish a container height, even though it's flexible 
                                height: auto; min-height: 200px; max-height: 75vh; 
                              }

  .modal-window.scrollable    { overflow: auto; }                                

  .modal-window-enter,
  .modal-window-leave-to      { transition: all 0.4s cubic-bezier(.21,.18,0,1); transform: translate(-50%, -100px); opacity: 0; }
                              
  .modal-window-enter-active, 
  .modal-window-leave-active  { transition: all 0.4s cubic-bezier(.21,.18,0,1); }
                                
                                
  .modal-window.has-controls  { padding-bottom: 56px; /* make room for modal controls*/ }
  
  .modal-content              { overflow: hidden; height: 100%; /* Clips overflowing content components against .modal-window stretch height */ }
  
  .modal-window h1            { display: inline-block; margin-top: 0;}
  .modal-window h2            { color: #454545; font-weight: normal; font-size: 18px; }

  .modal-progress {
    position: absolute;
    background: rgba(0,0,0,0.3);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br);
  }
  
  .modal-control { 
    position: absolute; 
    height: 44px; 
    left: 0; 
    bottom: 0; 
    right: 0; 
    padding: 0 16px; 
    display: flex; 
    justify-content: flex-end;
    
    button { white-space:nowrap; flex-basis: 90px; min-width: inherit; }

    *:not(:last-child) { margin-right: 8px;}
    .safe { margin-right: auto; }
    .default { min-width: 120px; margin-left: 16px;}
    .cancel:not(:last-child) { margin-right: 32px;}
    .left   { order: -1; }
    .danger { order: -1; }
  }

  
  
  @media screen and (max-width: 375px) {
    .modal-window {
      box-sizing: border-box;
      width: 100vw !important; 
      min-width: unset;
      max-width: unset;
      height: calc(100vh - 44px) !important;
      left: 0px;
      right: 0px;
      transform: none;
      top: 44px !important;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    
    .modal-window.foreground {
      border: 1px solid red;
    }
    
    .modal-window > * { 
      padding-left: 8px; 
      padding-right: 8px; 
    }
    
    .modal-window input {   
       width: 100%;
     }
     
    .modal-window select {
      min-width: auto;
      width: stretch;
    }
    
    .modal-cover {
      z-index: 199;
    }

//    .modal-control { left: 14px; right: 14px; }
  }
  
  @media (prefers-color-scheme: dark) {
    .modal-window {
      background: var(--popover-bgcolor);
      color: var(--text-color);
      
      h2 { 
        color: #cfcfcf; 
        font-weight: bold; 
        font-size: 18px; 
      }
      
    }
  }

</style>

<script>
  
  import Vue from 'vue'
    
  const ModalWindow = {
    
    props: {
      scrollable: {
        type: Boolean,
        default: false,
      }
    },
    
    data() { 
      return {
        $isModalWindow: true,
        modalCoverEl: null,
        showModal: false,
        loading: false,
        controlCallbacks: new Array(),
        showControlButtons: true,
        foreground: true
      }
    },
    
    created() {
      // Track the number of open windows so that new ones overlap old ones
      // if (window.modalWindowOpenCount === undefined)
      //   window.modalWindowOpenCount = 0;
      //
      // window.modalWindowOpenCount++
      console.debug("modal-window: enter");
      this.$root.$emit('modalWindowShow', this);
    },
    
    updated() {
      // Recursively set all children's $modalWindow property
      // to point to this modalWindow.
      //
      const setParentModalWindow = (vm) => { 
        if (!vm.$isModalWindow && vm != this) { 
          vm.$modalWindow = this 
        }
        vm.$children.forEach((c) => setParentModalWindow(c));
      };
      
      setParentModalWindow(this);
    },
    
    watch: {
      showModal(newValue) {
        if (newValue) {
          this.$children.forEach(child => child.mounted && child.mounted());
        }
      }
    },
        
    methods: {
      attemptAutofocus() {
        console.log("modalWindow attempt autofocus");
        let e = this.$el.querySelector('[autofocus]');
        if (e) {
          this.$nextTick(()=> {
            console.log("will focus", e);
            e.focus();
            e.select();
          })
        }
      },
      
      beforeEnter() {
        this.modalCoverEl = document.createElement('div');
        this.modalCoverEl.classList.add('modal-cover');
        this.modalCoverEl.classList.add('hidden');
        this.modalCoverEl.addEventListener('click', this.cancel)
        document.body.appendChild(this.modalCoverEl);
          
      },
      
      enter() {
        this.modalCoverEl.classList.remove('hidden');
      },
      
      beforeLeave() {
        this.modalCoverEl.classList.add('hidden');
      },
            
      afterLeave() {
        this.$emit('modalSessionExit', this);
        this.modalCoverEl.parentNode.removeChild(this.modalCoverEl);
      },
      
      
      show(e) {
        e && e.preventDefault();
        this.showModal = true;
      },
      
      cancel(e) {
        e && e.preventDefault();
        this.showModal = false;
        this.$emit('cancel', this);
        this.$root.$emit('modalWindowHide', this);
      },
      
      controlButtonClick(def, event) {
        let method = def['click'];
        method(event, this);
      },
      
      setControlButtonsShown(flag) {
        console.warn(`setControlButtonsShown(${flag})`);
        this.showControlButtons = flag;
      }
          
    },
    
    // Returns a promise that resolve in the next tick with the dynamically created child component
    //
    // ModalWindow.showModalWithParent(this, {
    //   childComponent: 'tattoo',
    //   childBindings: {
    //     linestringFeature: this.linestringFeature,
    //     aidStations: this.aidStations,
    //     colorOptions: this.colorOptions
    //   },
    // });
    //
    showModalWithParent(parent, opts={}) { 

      // Create the Modal Window modalWindow
      //
      let childInstance, slotElm;
      var ModalComponent = Vue.extend(ModalWindow);

      var modalWindow = new ModalComponent({
        parent: parent,
        components: opts.components,
        propsData: Object.assign({ /*default props*/}, opts.bindings)
      })
      modalWindow.$mount();
      document.body.appendChild(modalWindow.$el);

      if (opts.childComponent) {
        
        let ChildComponent = Vue.extend(opts.childComponent);

        childInstance = new ChildComponent({
          components: opts.components,
          parent: modalWindow,
          propsData: opts.childBindings
        });
        
        childInstance.$mount(); // generates $el
        slotElm = modalWindow.$createElement('div', childInstance)

        Vue.nextTick(() => {
          // create a vnode to go in the default modal window slot
          modalWindow.$slots.default = [slotElm];
          modalWindow.$forceUpdate();
        })
      }
    
      // Add buttons
      if (opts.buttons) {
        modalWindow.controlButtons = opts.buttons;
      }

      // Mount the modal window on the body
      //
      modalWindow.$on('cancel', (/*event*/) => {
        Vue.nextTick(() => { 
          console.debug("Removing ModalWindow", modalWindow)
          modalWindow.contentComponent.$destroy();
          modalWindow.$el.parentNode.removeChild(modalWindow.$el);
          modalWindow.$destroy();
          // parent.$forceUpdate();
        })
      })
    
      // Support keyboard interactivity
      modalWindow.closeOnEscape = opts.closeOnEscape;
    
      // Display the popover
      // parent.$forceUpdate();
      modalWindow.show();
    
      return new Promise((resolve) => {

        modalWindow.$forceUpdate()

        // We really do need two ticks for the slotElm to
        // generate a real DOM node.
        Vue.nextTick(() => { 
          Vue.nextTick(() => {
            slotElm.elm.appendChild(childInstance.$el);
            // modalWindow.$forceUpdate();
          
            modalWindow.contentComponent = childInstance;
            
            // Support autofocus
            modalWindow.attemptAutofocus();
            
            resolve(modalWindow)
          })
        })
      })
    }    
    
  }
  
  export default ModalWindow
  
</script>