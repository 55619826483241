<template>
  <div class="race-events" @escapepressed="escapePressed">
    <header class="sub">
      <h1>Currently Active Event</h1>
      <span class="flexible-space" />
      <button class="add" @click="editRaceEvent(null)">
        New Event
      </button>
    </header>
    
    <header v-if="activeRaceEvent" class="active sub inset-box">
      <h1>{{ activeRaceEvent.name }}</h1>
      <button class="mini inset" @click="editRaceEvent(activeRaceEvent)">
        Edit
      </button>
    </header>
    
    <div v-if="raceEventsLoading" class="loading">
      Loading...
    </div>
    
    <div v-if="raceEvents.length == 0" class="no-content">
      No Race Events
    </div>

    <ul v-else class="naked" ref="raceEventsList">
      <li v-for="raceEvent in raceEvents" :class="{selected: activeRaceEvent && raceEvent.id === activeRaceEvent.id}" @click.stop="setActiveRaceEvent(raceEvent)">
        <figure class="icon">
          <img :src="raceEventImageUrl(raceEvent)" alt="race logo" @error="raceEventImageError">
        </figure>
        <span class="name" :title="raceEvent.name">{{ raceEvent.name }}</span>
        <span class="year">{{ $root.shortDateFmt.format(raceEvent.startTime) }}</span>
      </li>
      <li class="full button">
        <button @click="loadMore">More</button>
      </li>
    </ul>
  </div>  
</template>

<style lang="scss" scoped>
  .race-events {
    display: flex;
    flex-direction: column;
    min-width: 420px; 
    padding-bottom: 8px;
    color: var(--text-color);
    max-height: calc(100vh - 200px);
    
    header { 
      padding: 0; 
      &>* {
        white-space: nowrap; 
      }

      h1 { font-size: 18px; margin-bottom: 4px; white-space: nowrap;}
      
      &.active {
        justify-content: space-between;
        padding: 1em;
        h1 {
          font-weight: 200;
        }
      }
    }

    .note { margin-top: 4px; font-size: 12px; color: #656565; text-align:left; }
  
    .loading,
    .no-content {
      text-align: center;
      padding: 1em 0;
    }
  
    ul {
      padding: 0 0 1em 0;
      flex: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto, 1fr);
      overflow-y: auto;
      align-items: left;
      
      li {
        margin: 0; padding: 0;
        vertical-align: middle;
        background: rgba(64,64,64,0.24);
        border-radius: var(--br);
        margin: 0.25em;
        padding: 0.5em 0.5em 0.5em calc(1em + 44px);
        cursor: pointer;
        position: relative;
        text-align: left;
        
        &.selected {
          font-weight: bold;
          background: var(--row-hover-bgcolor);
          border: 1px solid var(--text-dim1-color);
        }
        
        &:hover { 
          background: var(--row-hover-bgcolor); 
//          font-weight: bold;
        }

        &:active { 
          color: var(--text-dim1-color); 
          background: var(--dark-accent-color); 
        }
        
        &.full {
          grid-column-start: 1;
          grid-column-end: 3;
          padding: 0.5em;
        }
        
        &.button {
          // the row will show styling as a button like the selection rows.
          // the button itself needs to have no styling.
          background: transparent !important; 
          border: none;
          box-shadow: none;
          margin: 1em auto;
        }
        
        .icon {
          max-width: 44px;
          height: 36px;
          margin: 0;
          margin-right: 0.5em;
          overflow:hidden;
          
          position: absolute;
          left: calc(0.5em + (44px / 2));
          top: 50%;
          transform: translate(-50%, -50%);
          
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        
        span:before {
          // This bold (but hidden) text forces the layout to account for
          // larger bold lines so that layout won't be shifted on hover
          content: attr(title);
          font-weight: bold;
          display:block;
          height: 0;
          visibility:hidden;
        }
        
        .name { display: block; }
        .year {
          display: block;
          color: var(--text-dim1-color);
          font-weight: bold;
        }
      }
    }
  }
</style>

<script>
  import ModalWindow from "@/components/modal-window.vue"
  import Navigation from "@/components/navigation.vue"
  import Indicator from "@/components/indicator.vue"
  import LoadingSpinner from "@/components/loading-spinner.vue"
  import RaceEventDetails from "@/views/race-event-details.vue"
  import RaceDetails from "@/views/race-details.vue"
  import entities from '@/entity.js'

  import aravaipaLogo from '@/assets/aravaipa-logo.png'
  
  export default {
    
    components: {
      RaceEventDetails,
      RaceDetails,
      Indicator
    },
    
    data() {
      return {

      }
    },

    computed: {
      activeRaceEvent() { return this.$store.state.activeRaceEvent },
      raceEventsLoading() { return this.$store.state.raceEventsState == "loading" },
      raceEvents() { 
        const events = Object.values(this.$store.state.raceEvents);
        const firstRaceTime = re => {
          const races = this.$store.getters.entity('races', re.races);
          return races.reduce((a, r) => (r.startTime > a ? r.startTime : a), 0);
        }

        events.sort((a,b) => {
          a = a.startTime || firstRaceTime(a) || Infinity;
          b = b.startTime || firstRaceTime(b) || Infinity;
          if (a > b) return -1;
          if (a < b) return 1;
          return 0;
        });
        
        return events;
      },
    },
    
    mounted() {
      this.$root.$on('escapePressed', this.escapePressed);
    },
    
    destroyed() {
      this.$root.$off('escapePressed');
    },
    
    methods: {
      escapePressed(e) {
        return this.$parent.hide && this.$parent.hide();
      },
      
      editRaceEvent(raceEvent) {
        let parentComponent;
        if (this.$isInPopover) {
          // get a non-ephemeral parent, then close the popover
          parentComponent = this.$parent.$parent;
          this.$parent.hide();
        }
        else {
          parentComponent = this.$parent;
        }

        ModalWindow.showModalWithParent(parentComponent, {
          components: this.$options.components,
          closeOnEscape: true,
          childComponent: Navigation,
          childBindings: {
            navigationStack: [{name:'race-event-details', props: {raceEvent: raceEvent}}],
            raceEvent // passed as arg; might be null.
          },
          buttons: [
            {label: 'Cancel', class: 'cancel',  click: (e, modal) => { modal.cancel(); }},
            {label: 'Save',   class: 'default', click: (e, modal) => { 
                // Get the actual RaceEventDetails view.
                // The stack is ModalWindow -> Navigation -> RaceEventDetails
                // @TODO: The Navigation component needs to store its navigation stack components
                // in an accessible form. Probably need to look at the source for keep-alive to
                // understand where it's keeping them.
                //
                const raceEventDetailsView = modal.contentComponent.$children[0];
                this.saveRaceEvent(raceEventDetailsView.attributes).then((result) => {
                  modal.cancel();
                }); 
              }
            },
            
          ]// end of buttons array
        })
      },
      
      saveRaceEvent(raceEvent) {
        return this.$store.dispatch('saveEntity', {
          path: '/race_events/', 
          entity: entities.raceEvent, 
          object: raceEvent
        });
      },
      
      setActiveRaceEvent(raceEvent) {
        if (raceEvent == this.activeRaceEvent)
          return this.$parent.hide && this.$parent.hide();
        
        // this.$store.dispatch('setActiveRaceEvent', raceEvent);
        const currentRoute = this.$router.currentRoute
        
        if (currentRoute.params.raceEventId !== undefined) {
          // We are showing raceEvent-specific content; route to the
          // new version of whatever we're showing
          let route = {
            name: currentRoute.name,
            query: currentRoute.query,
            params: Object.assign({}, currentRoute.params),
          };
        
          let r = Object.assign({}, route);
          route.params.raceEventId = raceEvent.id;
          this.$router.push(route);
        
          // Why do I have to do this myself?
          this.$nextTick(() => {
            this.$nextTick(() => {
              // window.location.hash = this.$router.currentRoute.path;
              // console.log("race-events: new route: ", this.$router.currentRoute);
              // this.$router.go();
              this.$parent.hide && this.$parent.hide();
            });
          });
        }
        else {
          // Any navigation from our current route will not change the raceEventId param,
          // which results in duplicate navigation (asking for /thing => /thing, which just throws)
          this.$store.dispatch('setActiveRaceEvent', raceEvent);
          this.$parent.hide && this.$parent.hide();
          return;
        }
      },
      
      raceEventImageUrl(raceEvent) {
        if (raceEvent && raceEvent.avatarSmUrl) {
          return raceEvent.avatarSmUrl;
        }
        else if (raceEvent && raceEvent.avatarUrl) {
          return raceEvent.avatarUrl;
        }
        else {
          return aravaipaLogo;
        }
      },
      
      raceEventImageError(e) {
        e.target.src = aravaipaLogo;
      },
      
      loadMore() {
        this.$store.dispatch('loadRaceEvents', {usingOffset: true}).then(() => {
          // .scrollTo(0,999999);
          this.$refs.raceEventsList.scroll({
            top: 999999,
            behavior: 'smooth'  // 👈 
          });
        })
      }
      
    }
  }
</script>