<template>
  <div id="app" :class="{'force-expanded-nav': forceExpandedNav, 'expanded-nav': (forceExpandedNav || expandNav), 'expanded-sidebar': expandSidebar}">
    <header class="root main">
      <div class="backdrop-hack"></div>
      <popover-static
        className="hot-nav" 
        :title="raceButtonTitle" 
        :detail-text="raceButtonDetail" prefer="dn" class="race-button" 
        :img="raceEventImageUrl"
        @mouseover="mouseOverNav($event, true)" @mouseleave="mouseOverNav($event, false)"
      >
        <race-events />
        <template v-slot:alternate>
          <race-event-summary :race-event="activeRaceEvent" />
        </template>
      </popover-static>
      <search-bar :autosave-key="searchAutosaveKey" @resultClick="searchResultClick" />
      <race-event-status />
      
      <ul class="header-buttons">
        <li class="header-actions"><button @click="showActions" class="round" title="Actions"></button></li>
        <li class="sidebar-toggle" :data-alert="notifications.length ? notifications.length  : ''"><button class="round" title="Toggle Sidebar" @click="showSidebar(false)">Toggle Sidebar</button></li><!-- https://fontawesome.com/icons/caret-square-left?style=solid -->
      </ul>
      <transition name="toast">
        <div class="progress" v-if="showProgressToast">
          <h1>{{progressTag}}</h1>
          <progress :value="progressPercentage" max="100" />
        </div>
      </transition>
    </header>
    
    <nav id="main" @mouseover="mouseOverNav($event, true)" @mouseleave="mouseOverNav($event, false)" :class="{expanded: expandNav}">
      <ul>
        <li>
          <router-link tag="button" class="dashboard" to="/">Dashboard</router-link>
        </li>
        
        <li>
          <router-link tag="button" 
            class="schedule"
            :to="{name: 'schedule', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Schedule
          </router-link>
        </li>
        
        <li>
          <router-link tag="button"
            class="participants"
            :to="{name: 'participants', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Participants
          </router-link>
        </li>
        
        <li>
          <router-link tag="button"
            class="teams"
            :to="{name: 'teams', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Fastest Teams
          </router-link>
        </li>
        
        <li>
          <router-link tag="button"
            class="manual-entry"
            :to="{name: 'manual', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Manual Time
          </router-link>
        </li>

        <li>
          <router-link tag="button"
            class="addons"
            :to="{name: 'addons', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Addons
          </router-link>
        </li>
        
        <!-- <li>
          <router-link tag="button"
            class="podium"
            :to="{name: 'podium', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Podiums
          </router-link>
        </li> -->
        
        <!-- <li>
          <router-link tag="button"
            class="waves"
            :to="{name: 'wavegrid', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Waves
          </router-link>
        </li> -->
        
        <li>
          <router-link tag="button" 
            class="map"
            :to="{name: 'map', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Map
          </router-link>
        </li>
        
        <li>
          <router-link tag="button" 
            class="crossings"
            :to="{name: 'crossings', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Crossings
          </router-link>
        </li>

        <li>
          <router-link tag="button" 
            class="stats"
            :to="{name: 'stats', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Demographics
          </router-link>
        </li>
        
        
        <li>
          <router-link tag="button" 
            class="livestream"
            :to="{name: 'livestream', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
            :disabled="activeRaceIdMissing">
            Livestream
          </router-link>
        </li>
        
        <li class="flexible-space">&nbsp;</li>
        <li><hr class="separator">&nbsp;</hr></li>
        
        
        <li class="version hide-collapsed">
          <h1 class="logo" @click="toggleMobileMenu">Version</h1>
          <span class="branch">{{version.branch}} / {{version.hash}}</span>
          <span class="date">{{ version.date }}</span>
        </li>
        
        <li><hr class="separator">&nbsp;</hr></li>
        
        <li>
          <router-link tag="button" class="sources" to="/event-sources">Timing Points</router-link>
        </li>
        
        <li>
          <router-link tag="button" 
            class="settings"
            :to="{name: 'settings'}"
            >
            Settings
          </router-link>
        </li>
      </ul>
      
    </nav>

    <router-view class="rv main"/>
    <notification-bar v-if="expandSidebar" />

    <wave-planner v-if="showWavePlanner" />
    <ticker />
    
  </div>
</template>

<style lang="scss" src="@/assets/style.scss"></style>
<style lang="scss" src="@/assets/color.scss"></style>
<style lang="scss" src="@/assets/button.scss"></style>
<style lang="scss" src="@/assets/forms.scss"></style>
<style lang="scss" src="@/assets/varlist.scss"></style>
<style lang="scss" src="@/assets/app-layout.scss"></style>

<style lang="css"  src="@/assets/fonts/sfns.css"></style>
<style src="@/assets/fa.css"></style>

<style lang="scss">
  
</style>

<script>
  import Ticker from "@/components/ticker.vue"
  import PopoverStatic from "@/components/popover-static.vue"
  import RaceEvents from "@/views/race-events.vue"
  import RaceEventSummary from "@/views/race-event-summary.vue"
  import RaceEventStatus from "@/components/race-event-status.vue"
  import SearchBar from "@/components/search-bar.vue"
  import NotificationBar from "@/components/notification-bar.vue"
  import WavePlanner from "@/views/wave-planner.vue"

  import Popover from "@/components/popover.vue"
  import MenuContents from "@/components/menu-contents.vue"


  import GlobalActions from "@/lib/global-actions.js" // things dialogs can do
  import Actions from "@/lib/actions-menu.js" // header menu
  
  import schema from "@/entity.js"
  import {normalize} from 'normalizr'

  import {speak} from '@/lib/speech.js'

  import appver from '@/version.json'

  // Mouseover elements from navigation (the left sidebar and race event menu)
  const hotNavElms = new Set();

  export default {
    
    mixins: [GlobalActions],
    
    components: {
      Ticker,
      PopoverStatic,
      RaceEvents,
      RaceEventSummary,
      RaceEventStatus,
      SearchBar,
      NotificationBar,
      WavePlanner,
    },
    
    data() {
      
      let timeTravelDelta = 0;
      if (process.env.NODE_ENV !== 'production') {
        // timeTravelDelta = new Date('Sat, Dec 19 2020, 9:01:11 PM') - Date.now()
      }
      
      return {
        showProgressToast: false,
        progressPercentage: 0,
        progressTag: '',
        updateTimerTravel: timeTravelDelta, // set to milliseconds to time travel
        expandNav: false,
        timeouts: [], // setTimeout objects to buffer incoming requests.
        participantsBuffer: {},
        participantsTimeout: null,
        modalWindows: [],
        visibleComponent: undefined, // The root component that is ready to accept actions from the tools menu
        showWavePlanner: false, // bottom wave planner
      }
    },
    
    computed: {
      version(){ return appver},
      wantsMiles() { return this.$store.state.settings.wantsMiles; },
      forceExpandedNav() { return this.$store.state.settings.forceExpandedNav; },
      expandSidebar() { return this.$store.state.settings.expandSidebar; },
      
      notifications() { return Object.values(this.$store.state.notifications || {}) },
      
      activeRaceEventId() { 
        return this.$store.state.activeRaceEvent && this.$store.state.activeRaceEvent.id;
      },
      
      activeRaceEvent()   { 
        if (!this.activeRaceEventId)
          return undefined;

        return this.$store.state.raceEvents[this.activeRaceEventId];
      },
      
      activeRaceIdMissing() {
        return !this.activeRaceEventId || this.activeRaceEventId == 0;
      },
      
      races() {
        if (!this.activeRaceEvent) return [];
        return this.$store.getters.entity('races', this.activeRaceEvent.races);
      },
      
      activeRaces() { 
        const ar =  this.$store.state.activeRaces;
        return (ar.length < 1) ?
          this.races :
          ar;
      },
      
      raceButtonTitle()   { return this.activeRaceEvent ? this.activeRaceEvent.name : 'Choose an Event' },
      raceButtonDetail()  { 
        return ''; // @TODO show event date
      },
      
      searchAutosaveKey() {
        return 'raceEvent' + (this.activeRaceEvent && this.activeRaceEvent.id || 'global');
      },

      raceEventImageUrl() {
        if (this.activeRaceEvent && this.activeRaceEvent.avatarUrl) {
          return this.activeRaceEvent.avatarUrl;
        }
        
        return undefined;
      },
      
      actionMenuItems() {
        const separator = { type: 'separator' };
        const activeRaceEvent = this.$store.state.activeRaceEvent;
        

        let assignemntItems = [
          {
            title: 'Import Participants',
            action: () => Actions.showImportDialogFrom(this),
          },
          {
            title: `Assign Bibs…`,
            action: () => Actions.showAssignBibsFrom(this),
            disabled: false // figure out if there's already a start crossing
          },
          {
            title: `Assign Waves…`,
            action: () => Actions.showAssignWavesFrom(this),
            disabled: false 
          },
          {
            title: `Wave Planner…`,
            action: () => Actions.showWavePlannerFrom(this),
            disabled: false 
          },
        ];
        
        let exportItems = [
          {
            title: `Show Registration Sheet${this.activeRaces.length > 1 ? 's ('+this.activeRaces.length+')' : ''}`,
            action: () => { 
              this.activeRaces.forEach(race => {
                Actions.navigateTo(`/races/${race.id}/registration`, `race_${race.id}_reg`);
              })
            },
            disabled: this.activeRaces.length < 1 
          },
          {
            title: `Generate Packet Labels (${this.activeRaces.length})`,
            action: () => { 
              this.activeRaces.forEach(race => {
                Actions.navigateTo(`/races/${race.id}/packets.pdf`, `race_${race.id}_lab`);
              });
            },
            disabled: this.activeRaces.length < 1 
          },
          {
            title: `Export All Participants`,
            action: () => { 
              this.authorizedDownload(`/race_events/${activeRaceEvent.id}.csv`);
            },
          },
          separator,
          {
            title: `Export Results - UltraSignup`,
            action: () => { 
              this.activeRaces.forEach(race => {
                Actions.navigateTo(`/races/${race.id}/results.csv`);
              });
            },
            disabled: this.activeRaces.length < 1 
          },
          {
            title: `Export Results - ITRA`,
            action: () => {
              this.activeRaces.forEach(race => {
                Actions.navigateTo(`/races/${race.id}/results.csv?as=itra`);
              })
            },
            disabled: this.activeRaces.length < 1 
          },
          
          {
            title: `Export Results - UTMB`,
            action: () => {
              this.activeRaces.forEach(race => {
                Actions.navigateTo(`/races/${race.id}/results.xlsx?as=utmb`);
              })
            },
            disabled: this.activeRaces.length < 1 
          },
          
          {
            title: `Export Results - Crossings`,
            action: () => {
              this.activeRaces.forEach(race => {
                Actions.navigateTo(`/races/${race.id}/results.xlsx?as=sfink`);
              })
            },
            disabled: this.activeRaces.length < 1 || this.activeRaces.find(r=>!r.isTimed)
          },
          
          {
            title: `Export Results - HTML`,
            action: () => { 
              this.activeRaces.forEach(race => {
                Actions.navigateTo(`/races/${race.id}/results.html`);
              });
            },
            disabled: this.activeRaces.length < 1 
          },

        ];
        
        let lpsMonitorIsStarted = false;
        const lpsState = this.$store.state.lpsMonitorStatus;
        switch (lpsState) {
          case 'running': lpsMonitorIsStarted = true; break;
          case 'starting': lpsMonitorIsStarted = true; break;
          default:break;
        }
        
        let posIngressIsStarted = false;
        const pimState = this.$store.state.activeRaceEvent.posIngressState;
        switch (pimState) {
          case 'running': posIngressIsStarted = true; break;
          case 'starting': posIngressIsStarted = true;
          default: break;
        }
        
        let liveItems = [
          {
            title: `LiveTracking…`,
            action: () => Actions.showLivetrackingConfig(),
            disabled: true,
          },
          {
            title: `${lpsMonitorIsStarted ? 'Stop' : 'Start'} LPS Monitor`,
            action: () => Actions.sendLPSMonitorCommand(lpsMonitorIsStarted ? 'stop' : 'start', this.$cable),
          },
          {
            title: `${posIngressIsStarted ? 'Stop' : 'Start'} GPS Ingress`,
            action: () => {
              Actions.sendPosIngressCommand(
                posIngressIsStarted ? 'stop' : 'start', 
                this.$store.state.activeRaceEvent.id,
                this.$cable
              );
            },
              
          },
        ];
        
        let miscItems = [
          {
            title: 'Recalculate all Splits',
            action: () => this.visibleComponent.recalcAll(), // only the participants pane implements this.
            disabled: this.visibleComponent?.recalcAll == undefined,
          },
          {
            title: `Rescore ${this.activeRaces.map(r=>r.name).join(',')}`,
            action: () => this.$cable.perform({
              channel: 'ControlChannel', 
              action: 'rescore_race_event', 
              data: {
                raceIds: this.activeRaces.map(r=>r.id)
              }
            }), 
            disabled: this.activeRaces.length < 1
          },
          {
            title: `Auto DNS ${this.activeRaces.map(r=>r.name).join(',')}`,
            action: () => this.$cable.perform({
              channel: 'ControlChannel', 
              action: 'auto_dns', 
              data: {
                race_ids: this.activeRaces.map(r=>r.id)
              }
            }), 
            disabled: this.activeRaces.length < 1
          },
          {
            title: `Apply Drop Downs ${this.activeRaces.map(r=>r.name).join(',')}`,
            action: () => {
              if (confirm("Change races for all unfinished participants?")) {
                this.$cable.perform({
                  channel: 'ControlChannel', 
                  action: 'apply_drop_downs_for_races', 
                  data: {
                    raceIds: this.activeRaces.map(r=>r.id)
                  }
                });
              }
            }, 
            disabled: this.activeRaces.length < 1
          },
        ]
        
        let dangerItems = [
          {
            title: `Reload Clients`,
            class: '',
            action: () => { 
              this.$cable.perform({
                channel: 'ControlChannel', 
                action: 'reload_clients', 
                data: {
                }
              });
            },
          },
          {
            title: `Reset all races in ${activeRaceEvent && activeRaceEvent.name || 'event'}`,
            class: 'danger',
            action: () => { 
              if (confirm('Really remove all crossing data?')) {
                this.$cable.perform({
                  channel: 'ControlChannel', 
                  action: 'reset_race_event', 
                  data: {
                    raceEventId: this.$store.state.activeRaceEvent.id
                  }
                });
              }
            },
          },
        ];
        
        let debugItems;
        // if (process.env.NODE_ENV === 'development') {
        if (this.showAlternateActions) {
          debugItems = [
            separator,
            {
              title: `Environment: ${process.env.NODE_ENV}`,
              disabled: true,
            },
            {
              title: 'Export Crossings for Replay',
              action: () => {
                console.log('501');
              }
            }
          ]
        }
        else {
          debugItems = [];
        }
        
        let items = [
          ...assignemntItems,
          separator,
          ...exportItems,
          separator,
          ...liveItems,
          separator,
          ...miscItems,
          separator,
          ...dangerItems,
          ...debugItems
        ];
        return items;
      },
      
      dateFormatter() { 
        return new Intl.DateTimeFormat('en-US', {
          weekday: 'short', 
          month: 'short', 
          day: 'numeric', 
          hour: 'numeric', 
          minute: '2-digit', 
          second: '2-digit', 
          hour12: true,
        })
      },
    },
    
    /* https://github.com/mclintprojects/actioncable-vue */
    channels: {
      ControlChannel: {
        connected() {
          this.$store.commit('setChannelState', {channel: 'control', connected: true});
        },
        
        disconnected() {
          this.$store.commit('setChannelState', {channel: 'control', connected: false});
        },
        
        received(data) {
          //console.debug("WS: ", data);

          if (!data.msgType) {
            let found = false;
            if (data.update) {
              found = true;
              // console.debug("received model updates");
              
              Object.keys(data.update).forEach(key=> {
                if (!this.$store.state[key]) {
                  console.debug(`Unpersisted key update: ${key} (dispatching)`);
                  this.$root.$emit(`update:${key}`, data.update[key]);
                  delete data.update[key];
                }
                
                if (key == 'crossings') {
                  // Alert that a crossing happened
                  let updates   = data.update['crossings'];
                  let crossings = updates && Object.values(updates);
                  const es = this.$store.state.settings?.announcerEventSource;
                  this.announce(crossings, es);
                }
              });
              
              this.$store.dispatch('commitEntities', data.update)
            }
            
            if (data.create) {
              found = true;
              console.debug("received model creations");
              this.$store.dispatch('commitEntities', data.create)
            }
            
            if (found) return;

            data.msgType = data.msg_type 
          }

          if (!data || !data.msgType)
            return console.warn("App: Ignoring control data without msgType", data);
          
          switch (data.msgType) {
            case 'reload': 
              window.location.reload(false);
              break;
              
            case 'notification': {
              const note = data.notification;
              console.log("Receive Notification: ", note);
              let payload = {};
              payload[note.id] = note;
              
              this.$store.dispatch('commitEntities', {notifications: payload}).then(() => {
                this.$root.$emit('notification', note);
              });
              
              break;
            }
            
            case 'batch': {
              // the key 'entries' contains all the update messages.
              const entries = data.entries;
              const func = this.$options.channels.ControlChannel.received.bind(this); // arguments.callee is deprecated
              entries.forEach(func);
              break;
            }
            
            case 'crossing': 
              this.$store.dispatch('receiveCrossing', data.crossing);
              break;
            
            case 'pos_ing_status_change':
              this.$store.dispatch('setPosIngressStatus', data);
              break;
            
            case 'status_change': 
              console.debug("App: recv status_change:", data);
              this.$store.dispatch('setEventSourceStatus', data);
              break;

            case 'entityCreate':
            case 'entityCreated':
              this.entityCreated(data);
              break;

            case 'entityPlacements':
            case 'entityUpdate':
              // Edge case-- debounce participant creation
              // Maybe we ought to do this for everything?
              // if (data.name == 'participant')
              this.entityUpdated(data);
              break;
              
            case 'raceCreated':
              console.warn("message type '%s' deprecated", data.msg_type);
              this.raceCreated(data.race);
              break
              
            case 'participantCreated':
              console.warn("message type '%s' deprecated", data.msg_type);
              this.participantCreated(data.participant);
              break;
            
            case 'model_creation_complete':
              console.warn("message type '%s' deprecated", data.msg_type);
              this.$root.$emit('modelCreationComplete', data.context);
              break;
            
            case 'livetrackStatus':
              this.$store.dispatch('livetrackStatus', data.status)
              break;
              
            case 'progress':
              console.log("progress; ", data.tag, data.p);
              switch (data.tag) {
              case 'Recalculating Splits':
                this.$root.progress.$emit(data.tag, data);
                break;
              default:
                this.handleCurrentProgress(data);
                break;
              }
              break;
              
            case 'lps_monitor':
              if (data.status) {
                this.$store.commit('setLPSMonitorStatus', {status: data.status })
              }
              break;
              
            default:
              console.warn("Unknown message type '%s'", data.msgType, data);
              break;
          }
        }
      },
      
      EventChannel: {
        connected() {
          this.$store.commit('setChannelState', {channel: 'event', connected: true});
        },
        
        disconnected() {
          this.$store.commit('setChannelState', {channel: 'event', connected: false});
        },
        
        received(data) {

          if (!data) return;
          
          if (typeof data === 'string')
            data = JSON.parse(data);
          
          let normal = {};
          normal[data.id] = data;
          this.$store.dispatch('commitEntities', {crossings: normal}).then(() => {
            this.$root.$emit('crossing', data);
          });

        }
      }
    },
    
    mounted() {
      hotNavElms.add( document.querySelector('header.main') );
      hotNavElms.add( document.querySelector('nav#main') );
      
      this.loadRaceEventDetails();
      
      const isMac = navigator.platform.toLowerCase().match(/mac/); // 'MacIntel' or 'Linux x86_64, etc.
      
      this.expandNav = this.forceExpandedNav;
      
      // close the sidebar on mobile after routing
      this.$router.afterEach((to, from) => {
        if (!this.forceExpandedNav) {
          setTimeout(() => (this.expandNav = false), 200);
        }
      });
      
      this.$store.dispatch('loadEntities', {
        url: `/notifications`,
        entity: [schema.notification],
      })
      
      // Collapse temporarily expanded nav after ignoring a mouseleave
      document.body.addEventListener('click', this._cleanupMouseOverNavClick);
      
      document.body.addEventListener('keydown', (e) => {
                
        if (e.key === 'Escape') {
          e.preventDefault();
          console.debug("App: escapePressed");
          this.$root.$emit('escapePressed', e);
          
          // Cancel foreground modalWindow
          if (this.modalWindows.length > 0) {
            console.debug("escape. have %d modalwindows", this.modalWindows.length);
            const m = this.modalWindows[this.modalWindows.length-1]
            if (m.closeOnEscape) {
              console.debug("will close m", m);
              m.cancel();
            } 
            else {
              console.debug("will not close modal because closeOnEscape is not set", m);
            }
          }
          
          return;
        }
        else
        if (e.key == 'Enter')
        {
          this.$root.$emit('enterkey', e);
          
          // Click foreground modalWindow default button
          if (this.modalWindows.length > 0) {
            const l = this.modalWindows.length
            const m = this.modalWindows[l-1];
            console.log("got modal window", m);
            if (m && m.controlButtons) {
              const defaultButtons = m.controlButtons.filter(b => b.class.includes('default'))
              
              if (defaultButtons && defaultButtons.length && defaultButtons[0]) {
                console.debug("Clicking default button", defaultButtons[0], e);
                m.controlButtonClick(defaultButtons[0], e);
              }
            }
          }
        }
        else
        if ((isMac ? e.metaKey : e.ctrlKey) && e.key != 'Meta')
        {
          // This is a command
          //
          // Reserved key commands
          if (['r'].includes(e.key))
            return;

          console.log(`cmd-${e.key}`);
          this.$root.$emit(`cmd-${e.key}`.toLowerCase(), e);
        }
      });      
      
      // Refetch if we just logged in.
      this.$root.$on('loginDidSucceed', success=> this.doConnections());
      
      // ModalWindow Management
      //
      // Newest to 
      this.$root.$on('modalWindowShow', modal => {
        console.debug("detect new modal", modal);
        this.modalWindows.forEach(m => m.foreground = false);
        this.modalWindows.push(modal);
      });
      
      this.$root.$on('modalWindowHide', modal => {
        const i = this.modalWindows.indexOf(modal);
        console.debug("detect modalWindowHide:", modal, i);
        if (i >= 0) {
          this.modalWindows.splice(i, 1);
          const iTop = this.modalWindows.length - 1;
          this.modalWindows.forEach((m, i) => m.foreground = i == iTop);
        }
      });
      
      this.doConnections();
      this.startUpdateTimer();
    },
    
    watch: {
      '$route': function(r) {
        // instances is not set until the next tick.
        this.$nextTick(()=> {
          let component;
          
          if (this.$route.matched[0] && this.$route.matched[0].instances) {
            let instances = this.$route.matched[0].instances;
            component = instances?.default;
          }

          this.visibleComponent = component;
          console.log("visible component:", component);

          let cbs;
          if (cbs = component?.$options.activated) {
            if (!Array.isArray(cbs)) cbs = [cbs];
            cbs.forEach(cb => cb.apply(component));
          }
        })
      },
      
      activeRaceEvent(newVal, oldVal) {
        if (!newVal || !oldVal || newVal.id != oldVal.id) {
          console.debug(`activeRaceEvent changed to`, newVal, oldVal);

          if (this._pendingNavClose) {
            this._pendingNavClose = false;
            this.expandNav = false
          }
          
          this.loadRaceEventDetails();
        }
      },
      
      // When nav is forced expanded, expandedNav should be true.
      // When we stop forcing expanded nav, expandedNav needs to turn off.
      forceExpandedNav(val) {
        this.expandNav = val;
      },
    },
        
    methods: {
      doConnections() {
        console.log("Doing initial fetches");
        this.$cable.subscribe({ channel: 'ControlChannel'}); // To control event sources and send other command messages
        this.$cable.subscribe({ channel: 'EventChannel'});   // To receive bib crossing events from adapters
        this.$store.dispatch('loadEventSources');
        this.$store.dispatch('loadRaceEvents');
      },
      
      showActions(event) {
        this.showAlternateActions = event.altKey;
        
        Popover.showModalWithParent(this, event, {
          childComponent: MenuContents,
          childBindings: {
            menus: this.actionMenuItems,
          },
        });
      },
      

      async announce(crossings, es) {
        if (!this.$store.state.settings.speakAnnouncer) {
          return;
        }
        const pars = this.$store.state.participants;
        console.log("Will announce crossings", crossings);
        crossings?.forEach(async cr => {
          console.log(`-> Considering crossings at ${cr.eventSourceId} (announcer ${es})`)
          if (es == cr.eventSourceId && cr.participantId) {
            // We want to announce this participant
            const par = pars[cr.participantId];
            if (par) {
              console.log(`Announce ${par.firstName} ${par.lastName} bib ${par.bib}`);
              await speak(`${par.firstName} ${par.lastName} bib ${par.bib}`);
            }
          }
        });
      },
      
            
      loadRaceEventDetails() {
        // Load participants summary
        // It might be worthwhile to have some logic here to purge old participants
        // state data from raceEvents that haven't been used in a while. 
        //
        if (!this.activeRaceEvent)
          return;
        
        console.log(`Loading loadRaceEventDetails for ${this.activeRaceEvent.id}`)
        this.$store.dispatch('loadEntities', {
          url: `/race_events/${this.activeRaceEvent.id}`,
          entity: schema.raceEvent
        }).then(r=> console.debug(`✅ raceEvent ${this.activeRaceEvent.id} loaded`));
      },
      
      _cleanupMouseOverNavClick(e) {
        if (this._pendingNavClose) {
          
          // Check if we're navigating within the expanded nav
          let elm = e.target;
          console.log("Clicked", elm);
          console.log("Checking close against hotNavElms", hotNavElms);
          while (elm) {
            console.log("-> checking ", elm);
            if (hotNavElms.has(elm) || elm.classList.contains('hot-nav')) {
              // navigating inside the expanded nav; ignore this click
              return; 
            }
            elm = elm.parentElement;
          }

          this._pendingNavClose = false;
          this.expandNav = false;
        }
      },
      
      mouseOverNav(e, which){
        if (false == which) {
          if (e.currentTarget.tagName == 'NAV') {
            // We left the navigation bar
            let elm = e.relatedTarget;
            while (elm && elm != document.body) {
              if (hotNavElms.has(elm)) {
                // left to a hot element, for which the mouseover should stay active until next click
                this._pendingNavClose = true;
                return; 
              } 
              elm = elm.parentElement;
            }
          }
        }

        if (!this.forceExpandedNav) {
          this.expandNav = which;
        }
      },
      
      toggleMobileMenu() {
        this.expandNav = !this.expandNav;
      },
      
      showSidebar(which) {
        if (!which){
          // toggle.
          this.$store.commit('setting', {name: 'expandSidebar', value: !this.expandSidebar});
        }
      },
      
      raceCreated(race) {
        console.debug("raceCreated:", race);
        const data = normalize(race, schema.race);
        this.$store.dispatch('commitEntities', data);
      },
      
      entityUpdated(payload) {
        const modelEntity = schema[payload.model];

        if (!modelEntity) {
          console.error("entityUpdated: Unknown entity type", payload.model);
          return;
        }
        
        const data = normalize(payload[payload.model], modelEntity);
        console.debug("entityUpdated:", modelEntity.key, data.entities);

        this.$store.dispatch('commitEntities', data);
      },
      
      entityCreated(payload) {
        console.debug("entityCreated", payload);
        
        const modelEntity = schema[payload.model];
        if (!modelEntity) return console.error("Unknown entity type", payload.model);
        
        this.$root.$emit(payload.model + "CreateStarted");
        
        if (this.timeouts[payload.model])
          clearTimeout(this.timeouts[payload.model]);
        
        // For each entity that we receive from the normalization, create an entry in
        // the buffer. Once the timeout occurs, we'll commit the whole thing to Vuex.
        const data = normalize(payload[payload.model], modelEntity);
        
        
        this.$store.dispatch('commitEntities', data).then(() => {
          this.$root.$emit(payload.model + 'CreateFinished', data.entities);
          console.debug("Finished committing ", payload.model);
        })
      },
      
      /*
      commitBufferedEntities(name) {
        console.log("Committing buffered entitys", name);
        this.timeouts[name] = undefined;
        const data = Object.assign({}, this.participantsBuffer);
        this.participantsBuffer = {};
        this.$store.dispatch('commitEntities', data).then(() => {
          this.$root.$emit(name + 'CreateFinished');
          console.debug("Finished committing ", name);
        })
      },
      */
      
      participantCreated(participant) {
        
        this.$root.$emit('participantCreateStarted');
        
        if (this.participantsTimeout)
          clearTimeout(this.participantsTimeout);

        const data = normalize(participant, schema.participant);
        Object.keys(data.entities).forEach((entityName) => {
          if (!this.participantsBuffer[entityName])
            this.$set(this.participantsBuffer, entityName, {})
            
          Object.assign(this.participantsBuffer[entityName], data.entities[entityName]);
        })
        
        this.participantsTimeout = setTimeout(this.commitParticipants, 1000);
      },
      
      commitParticipants() {
        console.log("Committing participants");
        this.participantsTimeout = undefined;
        const data = Object.assign({}, this.participantsBuffer);
        this.participantsBuffer = {};
        this.$store.dispatch('commitEntities', data).then(() => {
          this.$root.$emit('participantCreateFinished');
        })
      },
      
      // Tick Timer
      
      startUpdateTimer() {
        this.stopUpdateTimer();
            
        // We don't want to start this with a fraction of a second until the next one.
        // This effectively syncs multiple clocks
        // We're waiting until the end of the second to schedule the interval.
        //
        if (window.updateTimerHandle === null) {
          setTimeout(()=> {
            console.log('startUpdateTimer');
            window.updateTimerHandle = setInterval(()=>this.updateTimerFired(), 1000);
          }, 1000 - (new Date().getMilliseconds() - 10));
        }
      },
    
      stopUpdateTimer() {
        if (window.updateTimerHandle) {
          console.log("stopUpdateTimer!");
          clearInterval(window.updateTimerHandle);
        }
      
        window.updateTimerHandle = null;
      },
      
      updateTimerFired() {
        this.$root.$emit('tick', new Date(Date.now() + this.updateTimerTravel));
        // this.stopUpdateTimer();
      },
      
      handleCurrentProgress(prog) {
        clearTimeout(this.progressTimeout);
        
        if (!this.showProgressToast) 
          this.showProgressToast = true;
        
        if (this.progressTag != prog.tag)
          this.progressTag = prog.tag;
          
        this.progressPercentage = prog.p;

        if (prog.p >= 100 || prog.p === undefined) {
          this.progressTimeout = setTimeout(() => {
            this.showProgressToast = false;
            setTimeout(() => this.progress = undefined, 750);
          }, 3000);
        }
      },
      
      searchResultClick(event) {
        if (event.type == 'participant') {
          this.$store.commit('pushRecentParticipant', event.subject);
      
          this.$router.push({
            name: 'participant', 
            params: {
              raceEventId: this.$store.state.activeRaceEvent.id,
              id: event.subject.id
            }
          });
        }
        else 
        if (event.type == 'raceEvent') {
          
        }
      }
    }
  }
</script>
