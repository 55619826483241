import ModalWindow from "@/components/modal-window.vue"
import ParticipantImport from "@/views/participant-import.vue"
import AssignBibs from "@/views/assign-bibs.vue"
import WaveAssignment from "@/views/wave-assignment.vue"
import WavePlanner from "@/views/wave-planner.vue"
import LiveTrackingDetails from "@/views/live-tracking-details.vue"

// Show the participant import dialog. Returns a promise that 
// resolves when the dialog is open.
//
function showImportDialogFrom(parent) {
  return ModalWindow.showModalWithParent(parent, {
    childComponent: ParticipantImport,
    childBindings: {
      
    },
    buttons: [
      {label: 'Cancel', class: 'cancel',  click: (e, modal) => { modal.cancel(); }},
      {label: 'Upload', class: 'default', click: (e, modal) => { modal.$children[0].doUpload(); }},
    ]
  })//.then((modalWindow) => { (modal exists here.) }
}


// Show the bib assignment dialog. Returns a promise that 
// resolves when the dialog is open.
//
function showAssignBibsFrom(parent) {
  return ModalWindow.showModalWithParent(parent, {
    childComponent: AssignBibs,
    childBindings: {
      
    },
    buttons: [
      {label: 'Done', class: 'default',  click: (e, modal) => { modal.cancel(); }},
    ]
  }); 
}


// Show the wave assignment dialog. Returns a promise that 
// resolves when the dialog is open.
//
function showAssignWavesFrom(parent) {
  ModalWindow.showModalWithParent(parent, {
    childComponent: WaveAssignment,
    childBindings: {
      
    },
    buttons: [
      {label: 'Clear', class: 'danger', click: (e,modal) => { modal.contentComponent.doClearWaves(); }},
      {label: 'Assign', class: '',  click: (e, modal) => { modal.contentComponent.doAssign(); }},
      {label: 'Done',   class: 'default',  click: (e, modal) => { modal.cancel(); }},
    ]
  });
}

function showWavePlannerFrom(parent) {
  ModalWindow.showModalWithParent(parent, {
    childComponent: WavePlanner,
    childBindings: {
      
    },
    buttons: [
      {label: 'Clear', class: 'danger', click: (e,modal) => { modal.contentComponent.doClearWaves(); }},
      {label: 'Assign', class: '',  click: (e, modal) => { modal.contentComponent.doAssign(); }},
      {label: 'Done',   class: 'default',  click: (e, modal) => { modal.cancel(); }},
    ]
  });
}


// Show the LiveTracking Config dialog. This is for local installs that
// need to ship data back to a central server. Returns a promise that 
// resolves when the dialog is open.
//
function showLivetrackingConfigFrom(parent) {
  return ModalWindow.showModalWithParent(parent, {
    childComponent: LiveTrackingDetails,
    childBindings: {
      
    },
    buttons: [
      {label: 'Stop', class: 'danger',  click: (e, modal) => { modal.$children[0].stopLivetracking() }},
      {label: 'Send Keyframe', class: '',  click: (e, modal) => { modal.$children[0].sendKeyframe(); }},
      {label: 'Cancel', class: 'cancel',  click: (e, modal) => { modal.cancel(); }},
      {label: 'Done', class: 'default',  click: (e, modal) => { 
        modal.$children[0].startLivetracking();
      }},
    ]
  });
}


// Tells the server to perform a command related to the LPS Monitor.
// 
function sendLPSMonitorCommand(cmd, cable) {
  cable.perform({channel: 'ControlChannel', action: 'lps_monitor_cmd', data: {cmd}})
}

function sendPosIngressCommand(cmd, raceEventId, cable) {
  cable.perform({channel: 'ControlChannel', action: 'pos_ingress_cmd', data: {cmd, raceEventId}})
}

// Navigates to a page in a new window. Use for downloading things from the server.
//
export function navigateTo(path, tabId) {
  let [hostname, port] = location.host.split(':');
  let url = `${location.protocol}//${hostname}`;
  
  if (port && port != 3000) {
    url += ':3000';
  }
  
  if (path[0] != '/')
    url += '/';
  
  url += path;
  
  console.log("Opening window for ", url);
  
  window.open(url, tabId, "noopener");
}

export default {
  showImportDialogFrom,
  showAssignBibsFrom,
  showAssignWavesFrom,
  showWavePlannerFrom,
  showLivetrackingConfigFrom,
  sendLPSMonitorCommand,
  sendPosIngressCommand,
  navigateTo,
}